var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "modal-header-label"
  }, [_vm._v("Справочник производителей")]), _c('div', {
    staticClass: "close-icon",
    on: {
      "click": _vm.close
    }
  }, [_c('close-svg')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }