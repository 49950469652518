<template>
  <div>
    <b-button
      style="margin-top: 22px"
      variant="primary"
      @click="openSidebar"
    >
      <img
        src="/img/icons/btn-plus.svg"
        alt=""
        class="mr"
      />
      Добавить
    </b-button>
    <b-form-input
      v-model="filter"
      placeholder="Поиск"
      type="text"
      class="filter-search"
    />
    <resizable-table
      style="margin-top: 16px"
      table_name="table-manufacture-list"
      :default_fields="fields"
      :items="filtered_items"
      @row-click="setManufacturer"
    >
      <template #body_id="{ value }">
        <div @click.stop>
          <b-checkbox
            v-model="selected"
            :value="value"
          />
        </div>
      </template>
      <template #body_region="{ value }">
        <div>{{ value?.name }}</div>
      </template>
    </resizable-table>
    <table-navbar
      :clear_selected="clearSelected"
      items_name="entity"
      hide_print
      hide_remove
      :items="selected"
      @edit="edit"
    />
  </div>
</template>

<script>
  import { EntityModel } from '@/models/entity.model'
  import ResizableTable from '@/components/ResizableTable'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'

  export default {
    name: 'BodyModal',
    components: { ResizableTable, TableNavbar },
    props: {
      items: {
        type: [EntityModel]
      }
    },
    data() {
      return {
        selected: [],
        filter: '',
        fields: [
          {
            key: 'id',
            label: '',
            checked: true,
            width: 50
          },
          {
            key: 'name',
            label: 'Имя',
            checked: true,
            width: 250
          },
          {
            key: 'region',
            label: 'Регион',
            checked: true,
            width: 170
          },
          {
            key: 'address_entity_str',
            label: 'Юр. адрес',
            checked: true,
            width: 330
          },
          {
            key: 'address_fact_str',
            label: 'Физ. адрес',
            checked: true,
            width: 330
          }
        ]
      }
    },
    computed: {
      filtered_items() {
        return this.items.filter(
          (el) =>
            el.name?.toLowerCase()?.includes(this.filter?.toLowerCase()) ||
            el.fullname?.toLowerCase()?.includes(this.filter?.toLowerCase())
        )
      }
    },
    methods: {
      openSidebar() {
        this.$emit('openSidebar')
      },
      edit(data) {
        this.$emit(
          'openSidebar',
          this.items?.find((el) => el.id === data)
        )
        this.clearSelected()
      },
      clearSelected() {
        this.selected = []
      },
      setManufacturer(manufacturer) {
        this.$emit('setManufacturer', manufacturer)
      }
    }
  }
</script>

<style scoped>
  .filter-search {
    padding-left: 32px;
    margin-top: 16px;
    background: url(/img/icons/input-search.svg) 12px center no-repeat;
    font-size: 14px;
    line-height: 16px;
  }
</style>
