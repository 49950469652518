var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "entity-sheet"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "entity-row",
      on: {
        "click": function ($event) {
          return _vm.row_click(item);
        }
      }
    }, [_c('div', {
      staticClass: "entity-name"
    }, [_vm._v(_vm._s(item.name || item.full_name))]), _c('div', {
      staticClass: "d-flex"
    }, [_c('div', {
      staticStyle: {
        "margin-right": "12px"
      }
    }, [_vm._v("ИНН: " + _vm._s(item.inn))]), _c('div', [_vm._v("КПП: " + _vm._s(item.kpp || '-'))])])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }