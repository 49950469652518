var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    staticStyle: {
      "margin-top": "22px"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.openSidebar
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить ")]), _c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "placeholder": "Поиск",
      "type": "text"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('resizable-table', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "table_name": "table-manufacture-list",
      "default_fields": _vm.fields,
      "items": _vm.filtered_items
    },
    on: {
      "row-click": _vm.setManufacturer
    },
    scopedSlots: _vm._u([{
      key: "body_id",
      fn: function (_ref) {
        var value = _ref.value;
        return [_c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-checkbox', {
          attrs: {
            "value": value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })], 1)];
      }
    }, {
      key: "body_region",
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_c('div', [_vm._v(_vm._s(value === null || value === void 0 ? void 0 : value.name))])];
      }
    }])
  }), _c('table-navbar', {
    attrs: {
      "clear_selected": _vm.clearSelected,
      "items_name": "entity",
      "hide_print": "",
      "hide_remove": "",
      "items": _vm.selected
    },
    on: {
      "edit": _vm.edit
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }