<template>
  <div class="d-flex">
    <div class="modal-header-label">Справочник производителей</div>
    <div
      class="close-icon"
      @click="close"
    >
      <close-svg />
    </div>
  </div>
</template>

<script>
  import CloseSvg from '@/views/products/components/modal/slots/CloseSvg'
  export default {
    name: 'HeaderModal',
    components: { CloseSvg },
    methods: {
      close() {
        this.$bvModal.hide('manufacture-modal')
      }
    }
  }
</script>

<style scoped>
  .modal-header-label {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    /* Text/primary - gray700 */

    color: #313131;
  }
  .close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #efefef;
    border-radius: 2px;
    margin-left: auto;
  }
</style>
