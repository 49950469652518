<template>
  <div class="entity-sheet">
    <div
      v-for="(item, index) of items"
      :key="index"
      class="entity-row"
      @click="row_click(item)"
    >
      <div class="entity-name">{{ item.name || item.full_name }}</div>
      <div class="d-flex">
        <div style="margin-right: 12px">ИНН: {{ item.inn }}</div>
        <div>КПП: {{ item.kpp || '-' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'EntitySheet',
    props: {
      items: Array
    },
    methods: {
      row_click(item) {
        this.$emit('select', item)
      }
    }
  }
</script>

<style scoped>
  .entity-sheet {
    position: absolute;
    right: 0;
    background: white;
    left: 0;
    z-index: 1;
    margin-top: 5px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    max-height: 50vh;
    overflow-y: scroll;
  }
  .entity-row {
    padding: 12px;
    border-bottom: 1px solid #e1e1e1;
  }

  .entity-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
  }
  .entity-row:hover {
    background: #f9f9f9;
  }
</style>
