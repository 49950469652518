<template>
  <section id="manufacture-modal-section">
    <b-modal
      id="manufacture-modal"
      centered
      size="xl"
      hide-footer
      hide-header
      class="p-0"
    >
      <manufacture-sidebar
        v-if="sidebar"
        :item="item"
        @cancel="cancel"
        @save="save"
      />
      <div style="padding: 22px; z-index: 2">
        <header-modal />
        <body-modal
          :items="items"
          @openSidebar="openSidebar"
          @setManufacturer="setManufacturer"
        />
      </div>
    </b-modal>
  </section>
</template>

<script>
  import ManufactureSidebar from '@/views/products/components/modal/ManufactureSidebar'
  import HeaderModal from '@/views/products/components/modal/slots/HeaderModal'
  import BodyModal from '@/views/products/components/modal/slots/BodyModal'
  import { EntityModel } from '@/models/entity.model'
  export default {
    name: 'ManufactureModal',
    components: { BodyModal, HeaderModal, ManufactureSidebar },
    apollo: {
      Entities: {
        query: require('../../gql/getEntities.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.items = data.Entities?.map((el) => new EntityModel(el)) || []
        }
      }
    },
    data() {
      return {
        sidebar: false,
        items: [],
        item: new EntityModel()
      }
    },
    methods: {
      setManufacturer(manufacturer) {
        this.$emit('setManufacturer', manufacturer)
      },
      openSidebar(data) {
        if (data) {
          this.item = new EntityModel(data)
        }

        this.sidebar = true
      },
      async save(data) {
        if (data.id) {
          await this.$apollo.mutate({
            mutation: require('../../gql/updateEntity.gql'),
            variables: {
              input: data.input
            }
          })
        } else {
          await this.$apollo.mutate({
            mutation: require('../../gql/createEntity.gql'),
            variables: {
              input: {
                ...data.input,
                is_manufacturer: true
              }
            }
          })
        }

        await this.$apollo.queries.Entities.refetch()
        this.sidebar = false
      },
      cancel() {
        this.sidebar = false
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .modal-dialog,
    .modal-content {
      /* 80% of window height */
      height: calc(100% - 120px);
      padding: 0px;
    }

    .modal-body {
      overflow-y: scroll;
      padding: 0;
    }

    .my-sidebar {
      //box-shadow: 4px 4px 8px 100px rgba(34, 60, 80, 0.2);
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background: white;
      box-shadow: -20px 0px 31px rgba(0, 0, 0, 0.04);
      border-radius: 0px 5px 5px 0px;
    }

    .slide-fade-enter-active {
      transition: all 0.3s ease;
    }
    .slide-fade-leave-active {
      transition: all 0.8s;
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
      transform: translateX(10px);
      opacity: 0;
    }
    .my-input {
      width: 283px;
    }
    .my-input-group {
      margin-bottom: 16px;
    }
  }
</style>
