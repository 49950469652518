var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-barcode",
      "centered": ""
    },
    on: {
      "hide": _vm.resetForm,
      "shown": _vm.changeModalBarcode
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Новый штрих-код")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }, {
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "new"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.addBarcode
          }
        }, [_vm._v(" Сохранить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_vm._v(" Отмена ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form', [_c('div', {
    staticClass: "barcode-group"
  }, [_c('label', [_vm._v("Штрих-код")]), !_vm.generated ? _c('div', {
    staticClass: "b-generate-bc cursor",
    on: {
      "click": _vm.generateBarcode
    }
  }, [_vm._v(" Сгенерировать штрих-код ")]) : _vm._e(), _c('div', {
    staticClass: "position-relative"
  }, [_vm.warning ? _c('b-tooltip', {
    attrs: {
      "target": "warning-img",
      "triggers": "hover"
    }
  }, [_vm._v(" 8 символов – весовая и табачная продукция 12+ символов – штучная продукция ")]) : _vm._e(), _vm.warning ? _c('img', {
    staticClass: "generate-barcode",
    attrs: {
      "id": "warning-img",
      "src": "/img/icons/info-warning.svg",
      "alt": "Ошибка"
    }
  }) : _vm.error_calc_barcode ? _c('warning-svg', {
    staticClass: "error_calc-img",
    attrs: {
      "id": "error_calc-img",
      "color": "#e53835"
    }
  }) : _c('img', {
    staticClass: "generate-barcode",
    attrs: {
      "src": "/img/icons/icon_scan_circle.svg",
      "alt": "Можно считать сканером"
    }
  }), _c('e-input', {
    ref: "inputBarcode",
    staticClass: "scan-circle",
    class: {
      warning: _vm.warning,
      error_calc: _vm.error_calc_barcode
    },
    attrs: {
      "error_text": "",
      "error": _vm.barcode_error,
      "type": "number",
      "required": "",
      "placeholder": "00000000000"
    },
    model: {
      value: _vm.barcode_item.barcode.barcode,
      callback: function ($$v) {
        _vm.$set(_vm.barcode_item.barcode, "barcode", $$v);
      },
      expression: "barcode_item.barcode.barcode"
    }
  })], 1)]), _vm.error_calc_barcode ? _c('p', {
    staticClass: "error_calc_barcode"
  }, [_vm._v(" Некорректная контрольная сумма ")]) : _vm._e(), _vm.warning ? _c('p', {
    staticClass: "warning-text"
  }, [_vm._v(" Некорректное количество символов ")]) : _vm._e(), _vm.barcode_error === 1 ? _c('p', {
    staticClass: "error mt-1"
  }, [_vm._v(" Необходимо ввести штрихкод ")]) : _vm._e(), _vm.barcode_error === 2 ? _c('p', {
    staticClass: "error mt-1"
  }, [_vm._v(" Такой штрихкод уже существует ")]) : _vm._e(), _c('div', {
    staticClass: "form-group mt-2"
  }, [_c('label', [_vm._v("Количесство в упаковке, шт")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "required": "",
      "placeholder": "0"
    },
    model: {
      value: _vm.barcode_item.value,
      callback: function ($$v) {
        _vm.$set(_vm.barcode_item, "value", $$v);
      },
      expression: "barcode_item.value"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }