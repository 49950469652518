<template>
  <b-modal
    id="modal-barcode"
    centered
    @hide="resetForm"
    @shown="changeModalBarcode"
  >
    <template #modal-header="{ close }">
      <h5>Новый штрих-код</h5>
      <b-button
        class="close"
        @click="close()"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <b-form>
      <div class="barcode-group">
        <label>Штрих-код</label>
        <div
          v-if="!generated"
          class="b-generate-bc cursor"
          @click="generateBarcode"
        >
          Сгенерировать штрих-код
        </div>
        <div class="position-relative">
          <b-tooltip
            v-if="warning"
            target="warning-img"
            triggers="hover"
          >
            8 символов – весовая и табачная продукция 12+ символов – штучная продукция
          </b-tooltip>
          <img
            v-if="warning"
            id="warning-img"
            class="generate-barcode"
            src="/img/icons/info-warning.svg"
            alt="Ошибка"
          />
          <warning-svg
            v-else-if="error_calc_barcode"
            id="error_calc-img"
            class="error_calc-img"
            color="#e53835"
          />
          <img
            v-else
            class="generate-barcode"
            src="/img/icons/icon_scan_circle.svg"
            alt="Можно считать сканером"
          />
          <e-input
            ref="inputBarcode"
            v-model="barcode_item.barcode.barcode"
            class="scan-circle"
            error_text=""
            :error="barcode_error"
            :class="{ warning: warning, error_calc: error_calc_barcode }"
            type="number"
            required
            placeholder="00000000000"
          />
        </div>
      </div>

      <p
        v-if="error_calc_barcode"
        class="error_calc_barcode"
      >
        Некорректная контрольная сумма
      </p>
      <p
        v-if="warning"
        class="warning-text"
      >
        Некорректное количество символов
      </p>
      <p
        v-if="barcode_error === 1"
        class="error mt-1"
      >
        Необходимо ввести штрихкод
      </p>
      <p
        v-if="barcode_error === 2"
        class="error mt-1"
      >
        Такой штрихкод уже существует
      </p>
      <div class="form-group mt-2">
        <label>Количесство в упаковке, шт</label>
        <b-form-input
          v-model="barcode_item.value"
          type="number"
          required
          placeholder="0"
        />
      </div>
    </b-form>
    <template #modal-footer>
      <div class="new">
        <b-button
          variant="primary"
          @click="addBarcode"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          @click="closeModal"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import * as R from 'ramda'
  import WarningSvg from '@/views/core/header/components/WarningSvg'

  export default {
    components: { WarningSvg },
    props: {
      barcodes: {
        type: Array,
        default: () => []
      },

      ean: {
        type: String,
        default: ''
      }
    },

    apollo: {
      GenerateBarcode: {
        query: require('../gql/GenerateBarcode.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            ean: this.ean
          }
        },
        result({ data }) {
          this.barcode_item.barcode.barcode = data?.GenerateBarcode?.barcode ?? ''
          this.ean_generate = true
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        skip() {
          return this.ean_generate
        }
      }
    },

    data: () => ({
      error_calc_barcode: false,
      warning: false,
      barcode_error: null,
      barcode_item: {
        value: 1,
        barcode: {
          barcode: ''
        }
      },
      ean_generate: true,
      generated: false
    }),

    watch: {
      'barcode_item.barcode.barcode'(val) {
        if (!val) {
          this.barcode_error = null
          this.warning = false
          this.error_calc_barcode = false
          return
        }
        this.barcode_error = null
        if (
          this.ean === 'weight'
            ? this.barcode_item.barcode.barcode.length < 7
            : this.barcode_item.barcode.barcode.length < 8
        ) {
          this.error_calc_barcode = false
          return (this.warning = true)
        } else {
          this.warning = false
        }
        if (
          this.ean !== 'weight'
            ? !this.valideBarcode(this.barcode_item.barcode.barcode)
            : this.barcode_item.barcode.barcode.length !== 7
        ) {
          this.error_calc_barcode = true
        } else {
          this.error_calc_barcode = false
        }
      }
    },

    methods: {
      resetForm() {
        this.barcode_item.value = 1
        this.barcode_item.barcode.barcode = ''
        this.barcode_error = null
        this.generated = false
        this.error_calc_barcode = false
        this.warning = false
      },

      changeModalBarcode() {
        this.$refs.inputBarcode.$refs.input.focus()
      },

      closeModal() {
        this.resetForm()
        this.$bvModal.hide('modal-barcode')
      },

      showModal() {
        this.resetForm()
        this.$bvModal.show('modal-barcode')
      },

      valideBarcode(barcode) {
        const code = barcode
        const digits = () => /^\d{8,13}$/g.test(code)
        const validlengths = [8, 12, 13]
        if (!digits() || !validlengths.includes(code.length)) return false

        let checksum = 0
        const codelist = code.split('')
        const checkdigit = parseInt(codelist.pop(), 10)
        codelist.map((value, index) => {
          const digit = parseInt(value, 10)
          if (code.length % 2 === 1) checksum += index % 2 ? digit * 3 : digit
          else checksum += index % 2 ? digit : digit * 3
        })

        let check = checksum % 10
        if (check !== 0) check = 10 - check
        if (check === checkdigit) return true
        return false
      },

      addBarcode() {
        const barcode = R.pick(['barcode_item'], this)

        if (!this.barcode_item.barcode.barcode.length) {
          this.error_calc_barcode = false
          this.warning = false
          return (this.barcode_error = 1)
        }

        this.$apollo
          .mutate({
            mutation: require('../gql/checkBarcode.gql'),
            variables: {
              barcode: this.barcode_item.barcode.barcode
            }
          })
          .then(({ data }) => {
            const statusOnServe = R.pathOr(false, ['CheckBarcode', 'status'], data)

            const statusOnUser = (barcodes) => {
              let status = false
              barcodes.forEach((item) => {
                if (item?.barcode?.barcode === this.barcode_item.barcode.barcode) status = true
              })
              return status
            }

            if (statusOnServe || statusOnUser(this.barcodes)) {
              this.error_calc_barcode = false
              this.warning = false
              return (this.barcode_error = 2)
            }

            this.$emit('add-barcode', barcode)
            this.barcode_error = false
            this.warning = false
            this.resetForm()
            this.$bvModal.hide('modal-barcode')
          })
          .catch((e) => {
            console.error('error: ', e)
          })
      },

      generateBarcode() {
        this.ean_generate = false
        this.generated = true
        this.$apollo.queries.GenerateBarcode.refetch()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .scan-circle {
    &.warning {
      ::v-deep input {
        border-radius: 2px;
        color: #ffa800;
        border: 1px solid #ffa800 !important;
      }
    }
    &.error_calc {
      ::v-deep input {
        border-radius: 2px;
        color: #e53835;
        border: 1px solid #e53835 !important;
      }
    }
  }

  .barcode-group {
    position: relative !important;

    .b-generate-bc {
      font-weight: 400;
      font-size: 14px;
      color: #00a3ff;
      position: absolute;
      top: 0;
      right: 0;
      text-decoration: underline;
    }

    img.generate-barcode {
      z-index: 2;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0%, -50%);
    }
  }
  .modal-footer {
    justify-content: flex-start !important;

    button {
      width: auto !important;
    }
  }

  :deep(.modal-footer) {
    .new {
      display: flex;
      justify-content: flex-start !important;
    }
  }
  .close {
    opacity: 1;
    &:hover {
      opacity: 1;
    }
  }
  .close:not(:disabled):not(.disabled):hover,
  .close:not(:disabled):not(.disabled):focus {
    opacity: 1;
  }
  .btn {
    padding: 0 12px;
  }
  .warning-text {
    margin-top: 4px;
    margin-bottom: 0;
    color: #ffa800;
  }
  ::v-deep .tooltip-inner {
    max-width: 320px !important;
    width: 320px !important;
    display: flex !important;
    text-align: left;
  }
  .error_calc_barcode {
    margin-top: 4px;
    margin-bottom: 0;
    color: #e53835;
  }
  .error_calc-img {
    z-index: 2;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0%, -50%);
  }
  ::v-deep .error_text {
    display: none;
  }
</style>
