var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "manufacture-modal-section"
    }
  }, [_c('b-modal', {
    staticClass: "p-0",
    attrs: {
      "id": "manufacture-modal",
      "centered": "",
      "size": "xl",
      "hide-footer": "",
      "hide-header": ""
    }
  }, [_vm.sidebar ? _c('manufacture-sidebar', {
    attrs: {
      "item": _vm.item
    },
    on: {
      "cancel": _vm.cancel,
      "save": _vm.save
    }
  }) : _vm._e(), _c('div', {
    staticStyle: {
      "padding": "22px",
      "z-index": "2"
    }
  }, [_c('header-modal'), _c('body-modal', {
    attrs: {
      "items": _vm.items
    },
    on: {
      "openSidebar": _vm.openSidebar,
      "setManufacturer": _vm.setManufacturer
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }