<template>
  <div class="my-sidebar-group">
    <div class="my-sidebar-backdrop"></div>

    <div class="my-sidebar p-3 d-flex flex-column">
      <div
        v-click-outside="clickedOutside"
        class="my-input-group"
      >
        <label>ИНН</label>
        <b-form-input
          v-model="form.inn"
          class="my-input"
          maxlength="20"
          type="text"
          required
          @input="searchEntity"
          @focus="show_sheet = true"
        />
        <div>
          <entity-sheet
            v-show="show_sheet"
            :items="entity_list"
            @select="setEntity"
          />
        </div>
      </div>
      <div class="my-input-group">
        <label>КПП</label>
        <b-form-input
          v-model="form.kpp"
          class="my-input"
          maxlength="20"
          type="text"
          required
        />
      </div>
      <div class="my-input-group">
        <label>Наименование</label>
        <b-form-input
          v-model="form.name"
          class="my-input"
          maxlength="20"
          type="text"
          required
        />
      </div>
      <div class="my-input-group">
        <label>Регион</label>
        <i-select
          filterable
          :value="form.region?.id"
          transfer
          transfer-class-name="my-select-list"
          @on-change="setRegion"
        >
          <i-option
            v-for="item in regions"
            :key="item.id"
            :value="item.id"
          >
            {{ item.full_name }}
          </i-option>
        </i-select>
      </div>
      <div class="my-input-group">
        <label>Юр. адрес</label>
        <b-form-input
          class="my-input"
          :value="form.address_entity_str"
          maxlength="20"
          disabled
          type="text"
          required
        />
      </div>
      <div class="my-input-group">
        <label>Физ. адрес</label>
        <b-form-input
          class="my-input"
          maxlength="20"
          :value="form.address_fact_str"
          disabled
          type="text"
          required
        />
      </div>
      <div class="mt-auto d-flex">
        <b-button
          class="mr-2"
          variant="primary"
          @click="save"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Отмена
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { EntityModel } from '@/models/entity.model'
  import EntitySheet from '@/views/products/components/modal/EntitySheet'

  export default {
    name: 'ManufactureSidebar',
    components: { EntitySheet },
    props: {
      item: EntityModel
    },
    data() {
      return {
        form: this.item,
        timer: null,
        entity_list: [],
        regions: [],
        show_sheet: false
      }
    },
    apollo: {
      Regions: {
        query: require('../../gql/Regions.gql'),
        result({ data }) {
          this.regions = data.Regions
        }
      }
    },
    watch: {
      item: {
        handler(val) {
          this.form = new EntityModel(val)
        }
      }
    },
    methods: {
      setRegion(id) {
        this.form.region = this.regions.find((el) => el.id === id)
      },
      clickedOutside() {
        this.show_sheet = false
      },
      save() {
        this.$emit('save', this.form)
      },
      cancel() {
        this.$emit('cancel')
      },
      setEntity(entity) {
        this.form = new EntityModel(entity)
        this.show_sheet = false
      },
      searchEntity(str) {
        clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../gql/getEntityDaData.graphql'),
            variables: {
              inn: str
            }
          })
          this.entity_list = data.Dadata
        }, 500)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .my-select-list {
      width: 283px;
      .ivu-select-item {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .modal-dialog,
    .modal-content {
      /* 80% of window height */
      height: calc(100% - 120px);
      padding: 0px;
    }

    .modal-body {
      overflow-y: scroll;
      padding: 0;
    }

    .slide-fade-enter-active {
      transition: all 0.3s ease;
    }
    .slide-fade-leave-active {
      transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
      transform: translateX(10px);
      opacity: 0;
    }
  }

  .my-sidebar {
    //box-shadow: 4px 4px 8px 100px rgba(34, 60, 80, 0.2);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    box-shadow: -20px 0px 31px rgba(0, 0, 0, 0.04);
    border-radius: 0px 5px 5px 0px;
    z-index: 99;
  }
  .my-input {
    width: 283px;
  }
  .my-input-group {
    margin-bottom: 16px;
    position: relative;
  }
  .my-sidebar-backdrop {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: black;
    opacity: 0.08;
    position: absolute;
    z-index: 98;
  }
</style>
