<template>
  <div>
    <manufacture-modal @setManufacturer="setManufacturer" />
    <label>Производитель</label>
    <div
      class="b-select-group"
      style="word-wrap: break-word; overflow: hidden; white-space: nowrap"
      @click="openManufacturerSelect"
    >
      <div class="image-group">
        <img
          src="/img/icons/products/pencil-edit.svg"
          alt="groupIcon"
        />
      </div>
      {{ manufacturer?.name || manufacturer?.full_name || 'Выберите производителя' }}
    </div>
  </div>
</template>

<script>
  import ManufactureModal from '@/views/products/components/modal/ManufactureModal'
  import { EntityModel } from '@/models/entity.model'
  export default {
    name: 'ManufacturerSelect',
    components: { ManufactureModal },
    props: {
      manufacturer: EntityModel
    },
    methods: {
      openManufacturerSelect() {
        this.$bvModal.show('manufacture-modal')
      },
      setManufacturer(manufacturer) {
        this.$emit('setManufacturer', manufacturer)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .b-select-group {
    position: relative;
    border: 1px solid #bcbcbc;
    border-radius: 2px;
    height: 32px;
    font-size: 14px;
    padding: 5px 10px;
    font-weight: 400;
    line-height: 1.5;
    cursor: pointer;
    .image-group {
      position: absolute;
      right: 0;
      top: 50%;
      width: 30px;
      height: 100%;
      background-color: #fff;
      z-index: 2;
      img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 50%;
        right: 7px;
        transform: translate(0%, -50%);
      }
      transform: translate(0%, -50%);
    }
  }
</style>
