var render = function render(){
  var _vm$manufacturer, _vm$manufacturer2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('manufacture-modal', {
    on: {
      "setManufacturer": _vm.setManufacturer
    }
  }), _c('label', [_vm._v("Производитель")]), _c('div', {
    staticClass: "b-select-group",
    staticStyle: {
      "word-wrap": "break-word",
      "overflow": "hidden",
      "white-space": "nowrap"
    },
    on: {
      "click": _vm.openManufacturerSelect
    }
  }, [_vm._m(0), _vm._v(" " + _vm._s(((_vm$manufacturer = _vm.manufacturer) === null || _vm$manufacturer === void 0 ? void 0 : _vm$manufacturer.name) || ((_vm$manufacturer2 = _vm.manufacturer) === null || _vm$manufacturer2 === void 0 ? void 0 : _vm$manufacturer2.full_name) || 'Выберите производителя') + " ")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-group"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/products/pencil-edit.svg",
      "alt": "groupIcon"
    }
  })]);

}]

export { render, staticRenderFns }