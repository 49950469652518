var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "1.10547",
      "y": "2.41016",
      "width": "1.84615",
      "height": "12",
      "rx": "0.923077",
      "transform": "rotate(-45 1.10547 2.41016)",
      "fill": "#BCBCBC"
    }
  }), _c('rect', {
    attrs: {
      "x": "2.41016",
      "y": "10.8945",
      "width": "1.84615",
      "height": "12",
      "rx": "0.923077",
      "transform": "rotate(-135 2.41016 10.8945)",
      "fill": "#BCBCBC"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }