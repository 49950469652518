var render = function render(){
  var _vm$form$region;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-sidebar-group"
  }, [_c('div', {
    staticClass: "my-sidebar-backdrop"
  }), _c('div', {
    staticClass: "my-sidebar p-3 d-flex flex-column"
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.clickedOutside,
      expression: "clickedOutside"
    }],
    staticClass: "my-input-group"
  }, [_c('label', [_vm._v("ИНН")]), _c('b-form-input', {
    staticClass: "my-input",
    attrs: {
      "maxlength": "20",
      "type": "text",
      "required": ""
    },
    on: {
      "input": _vm.searchEntity,
      "focus": function ($event) {
        _vm.show_sheet = true;
      }
    },
    model: {
      value: _vm.form.inn,
      callback: function ($$v) {
        _vm.$set(_vm.form, "inn", $$v);
      },
      expression: "form.inn"
    }
  }), _c('div', [_c('entity-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show_sheet,
      expression: "show_sheet"
    }],
    attrs: {
      "items": _vm.entity_list
    },
    on: {
      "select": _vm.setEntity
    }
  })], 1)], 1), _c('div', {
    staticClass: "my-input-group"
  }, [_c('label', [_vm._v("КПП")]), _c('b-form-input', {
    staticClass: "my-input",
    attrs: {
      "maxlength": "20",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.kpp,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kpp", $$v);
      },
      expression: "form.kpp"
    }
  })], 1), _c('div', {
    staticClass: "my-input-group"
  }, [_c('label', [_vm._v("Наименование")]), _c('b-form-input', {
    staticClass: "my-input",
    attrs: {
      "maxlength": "20",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('div', {
    staticClass: "my-input-group"
  }, [_c('label', [_vm._v("Регион")]), _c('i-select', {
    attrs: {
      "filterable": "",
      "value": (_vm$form$region = _vm.form.region) === null || _vm$form$region === void 0 ? void 0 : _vm$form$region.id,
      "transfer": "",
      "transfer-class-name": "my-select-list"
    },
    on: {
      "on-change": _vm.setRegion
    }
  }, _vm._l(_vm.regions, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.full_name) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "my-input-group"
  }, [_c('label', [_vm._v("Юр. адрес")]), _c('b-form-input', {
    staticClass: "my-input",
    attrs: {
      "value": _vm.form.address_entity_str,
      "maxlength": "20",
      "disabled": "",
      "type": "text",
      "required": ""
    }
  })], 1), _c('div', {
    staticClass: "my-input-group"
  }, [_c('label', [_vm._v("Физ. адрес")]), _c('b-form-input', {
    staticClass: "my-input",
    attrs: {
      "maxlength": "20",
      "value": _vm.form.address_fact_str,
      "disabled": "",
      "type": "text",
      "required": ""
    }
  })], 1), _c('div', {
    staticClass: "mt-auto d-flex"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Сохранить ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" Отмена ")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }